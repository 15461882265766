import React from "react";
import styled from "styled-components";
import HeroSection from "../components/heroSection";
import TeaMenu from "../components/teaMenu";
import SnackMenu from "../components/snackMenu";
import ShopLocation from "../components/shopLocation";

const Home = () => {
  return (
    <HomeContainer>
      <HeroSection />
      <div className="seperator"></div>
      <p className="title1">MENU</p>
      <SnackMenu />
      <TeaMenu />
      <div className="seperator"></div>
      <ShopLocation />
      <div className="seperator"></div>
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 30px;
  .title1 {
    margin: 0;
    margin-top: 10px;
    display: flex;
    font-size: 50px;
    font-family: "Staatliches", cursive;
    color: #fbb415;
    z-index: 100;
  }
  .seperator {
    width: 90vw;
    height: 1px;
    background-color: white;
    opacity: 20%;
  }
`;
