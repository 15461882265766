import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import donut from "../../media/donut.png";
import samosa from "../../media/samosa.png";

const SnackMenu = () => {
  const [snack, setSnack] = useState(donut);
  const [snackName, setSnackName] = useState("donut");
  return (
    <Menu>
      <p className="title">Quick Bites</p>
      <div className="products">
        <motion.div
          className="variants"
          initial={{ y: 200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <p
            className={snackName === "donut" ? "variant selected" : "variant"}
            onClick={() => {
              setSnack(donut);
              setSnackName("donut");
            }}
          >
            Donut
          </p>
          <p
            className={snackName === "samosa" ? "variant selected" : "variant"}
            onClick={() => {
              setSnack(samosa);
              setSnackName("samosa");
            }}
          >
            Samosa
          </p>
        </motion.div>
        <motion.img
          initial={{ y: 200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          src={snack}
          className="img"
        ></motion.img>
      </div>
    </Menu>
  );
};

export default SnackMenu;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .products {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .title {
    margin: 0;
    margin-top: 10px;
    display: flex;
    font-size: 30px;
    font-family: "Staatliches", cursive;
    color: #fbb415;
    z-index: 100;
  }
  .variants {
  }
  .img {
    width: 30vw;
  }
  .variant {
    color: white;
    cursor: pointer;
  }
  .selected {
    text-decoration: underline;
    text-underline-offset: 3px;
    color: #fbb415;
  }
`;
