import React from "react";
import styled from "styled-components";
import cycle from "../../media/cycle.gif";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const ShopLocation = () => {
  return (
    <LocationContainer>
      <p className="title1">90ML Near You</p>
      <motion.img
        src={cycle}
        className="cycle"
        alt=""
        initial={{ x: 0 }}
        whileInView={{ x: window.innerWidth }}
        transition={{ duration: 2 }}
      ></motion.img>
    </LocationContainer>
  );
};

export default ShopLocation;

const LocationContainer = styled.div`
  .cycle {
    width: 100px;
  }
`;
