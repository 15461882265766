import React from "react";
import styled from "styled-components";
import Logo from "../media/logo.png";

const Header = () => {
  return (
    <HeaderContainer>
      <img src={Logo} alt="" className="logo" />
      <div className="navigation">
        <button className="order-btn">Order Now</button>
      </div>
    </HeaderContainer>
  );
};

export default Header;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 70px;
  background: black;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  justify-content: space-between;
  padding: 0 10px;

  .logo {
    margin: 5px 0;
  }
  .navigation {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-right: 20px;
    align-items: center;
  }
  .order-btn {
    display: flex;
    align-items: center;
    height: 40px;
    background: none;
    border: none;
    color: white;
    font-size: 15px;
    padding: 10px;
    font-weight: 500;
    border-radius: 4px;
    font-family: "Poppins";
    background-color: #fbb415;
    box-shadow: -3px 3px #b75a22;
    cursor: pointer;
  }
`;
