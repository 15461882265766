import React from "react";
import styled from "styled-components";
import Glass from "../../media/glassTransparent.png";
import Chai from "../../media/withChai.png";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const HeroSection = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <HeroContainer>
      <p className="title">{`90 ML`}</p>
      <p className="title2">{`#CallItTeaTime`}</p>
      <motion.div className="chai">
        <img src={Glass} className="glass-img" alt=""></img>
        <motion.img
          src={Chai}
          className="chai-img"
          alt=""
          style={{
            height: useTransform(scaleX, (value) => `${380 - value * 1500}px`),
          }}
        ></motion.img>
      </motion.div>
    </HeroContainer>
  );
};

export default HeroSection;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .chai {
    display: flex;
    align-items: center;
    position: relative;
    background: transparent;
  }
  .glass-img {
    width: 300px;
    height: 450px;
    position: relative;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 1px;
  }
  .chai-img {
    width: 300px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    object-fit: cover;
    object-position: bottom;
    bottom: 1px;
  }
  .title2 {
    margin: 0 auto;
    /* margin-top: 30px; */
    display: flex;
    font-size: 20px;
    font-family: "Staatliches", cursive;
    color: #fbb415;
    text-align: center;
    z-index: 100;
  }
  .title {
    margin: 0 auto;
    /* margin-top: 10px; */
    display: flex;
    font-size: 10px;
    font-family: "Staatliches", cursive;
    color: #fbb415;
    text-align: center;
    z-index: 100;
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 100px;
      text-align: left;
    }
  }
`;
