import React, { useState } from "react";
import styled from "styled-components";
import blackTea from "../../media/blacktea.png";
import { motion } from "framer-motion";
import productImg from "../../media/chaiProduct.png";
import lemon from "../../media/lemonGrass.png";
import herbal from "../../media/herbalTea.png";

const TeaMenu = () => {
  const [tea, setTea] = useState(productImg);
  const [teaName, setTeaName] = useState("masala");

  return (
    <Container>
      <p className="title">Tea</p>
      <div className="products">
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="variants"
        >
          <p
            className={teaName === "masala" ? "variant selected" : "variant"}
            onClick={() => {
              setTea(productImg);
              setTeaName("masala");
            }}
          >
            Masala Chai
          </p>
          <p
            className={
              teaName === "black-ginger" ? "variant selected" : "variant"
            }
            onClick={() => {
              setTea(blackTea);
              setTeaName("black-ginger");
            }}
          >
            Black Ginger Chai
          </p>
          <p
            className={teaName === "lemon" ? "variant selected" : "variant"}
            onClick={() => {
              setTea(lemon);
              setTeaName("lemon");
            }}
          >
            Lemon Grass
          </p>
          <p
            className={teaName === "herbal" ? "variant selected" : "variant"}
            onClick={() => {
              setTea(herbal);
              setTeaName("herbal");
            }}
          >
            Herbal Chai
          </p>
          <p
            className={teaName === "special" ? "variant selected" : "variant"}
            onClick={() => {
              setTea(productImg);
              setTeaName("special");
            }}
          >
            90ML Special
          </p>
        </motion.div>
        <motion.img
          initial={{ y: 200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          src={tea}
          className="img"
        ></motion.img>
      </div>
    </Container>
  );
};

export default TeaMenu;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;


  .products {
    display: flex;
    justify-content: space-between;
  }
  .title {
    margin: 0;
    margin-top: 10px;
    display: flex;
    font-size: 30px;
    font-family: "Staatliches", cursive;
    color: #fbb415;
    z-index: 100;
  }
  .variants {
  }
  .img {
    width: 30vw;
  }
  .variant {
    color: white;
    cursor: pointer;
  }
  .selected {
    text-decoration: underline;
    text-underline-offset: 3px;
    color: #fbb415;
  }
`;
